import React, { useState } from "react";
import { useQuery } from "react-query";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Grid,
  Button,
  Dialog,
  DialogContent,
  TextField,
  InputAdornment,
  CircularProgress,
} from "@mui/material";
import { getRequestUI } from "common-utils/utils/api";
import { DateTime } from "luxon";
import { DateRange } from "react-date-range";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useOutletContext } from "react-router-dom";

const formatDate = (date) => DateTime.fromJSDate(date).toFormat("yyyy-MM-dd");
const formatDateDisplay = (date) =>
  DateTime.fromJSDate(date).toFormat("dd LLL yyyy");

const RetailerBreakdown = () => {
  const today = new Date();
  const [selectedStartDate, setSelectedStartDate] = useState(
    new Date(today.getFullYear(), today.getMonth(), today.getDate() - 30),
  );
  const [selectedEndDate, setSelectedEndDate] = useState(today);
  const [open, setOpen] = useState(false);
  // const [selectedCarrier, setSelectedCarrier] = useState("");
  const { auth, userClaims } = useOutletContext() || {};
  const retailerId = userClaims?.retailerId;

  const handleDateChange = (ranges) => {
    const { startDate, endDate } = ranges.selection;
    setSelectedStartDate(startDate);
    setSelectedEndDate(endDate);
  };

  const handleDialogClose = () => setOpen(false);
  const handleFieldClick = () => setOpen(true);

  const fetchAnalyticsData = async (endpoint) => {
    const filters = {
      startDate: formatDate(selectedStartDate),
      endDate: formatDate(selectedEndDate),
    };
    return getRequestUI(endpoint, filters);
  };

  const queryOptions = {
    enabled: !!selectedStartDate && !!selectedEndDate,
    refetchOnWindowFocus: false,
  };

  const { data: onTimePerformanceData, isLoading } = useQuery(
    ["on-time-performance", selectedStartDate, selectedEndDate],
    () =>
      fetchAnalyticsData("retailer/analytics/break-down/ontime-performance"),
    queryOptions,
  );

  const { data: totalFailedDeliveriesData } = useQuery(
    ["total-failed-orders", selectedStartDate, selectedEndDate],
    () =>
      fetchAnalyticsData("retailer/analytics/break-down/total-failed-orders"),
    queryOptions,
  );
  const { data: hitRateData } = useQuery(
    ["first-attempt", selectedStartDate, selectedEndDate],
    () => fetchAnalyticsData("/retailer/analytics/break-down/hit-rate"),
    queryOptions,
  );

  const { data: lateCulpabilitiesPerformanceData } = useQuery(
    ["late-culpabilities-performance", selectedStartDate, selectedEndDate],
    () =>
      fetchAnalyticsData("retailer/analytics/break-down/late-cuplabilities"),
    queryOptions,
  );

  const { data: failedDeliveriesData } = useQuery(
    ["failed-deliveries", selectedStartDate, selectedEndDate],
    () =>
      fetchAnalyticsData("/retailer/analytics/break-down/failed-deliveries"),
    queryOptions,
  );

  const { data: vehicleTypeData } = useQuery(
    ["vehicle-type", selectedStartDate, selectedEndDate],
    () => fetchAnalyticsData("/retailer/analytics/break-down/vehicle-type"),
    queryOptions,
  );

  const { data: deliveryPerformanceData } = useQuery(
    ["delivery-performance", selectedStartDate, selectedEndDate],
    () =>
      fetchAnalyticsData("/retailer/analytics/break-down/delivery-performance"),
    queryOptions,
  );

  const { data: totalDeliveredData } = useQuery(
    ["total-delivered", selectedStartDate, selectedEndDate],
    () => fetchAnalyticsData("retailer/analytics/break-down/total-delivered"),
    queryOptions,
  );

  return (
    <>
      {/* Date Picker Section */}
      <Box
        sx={{
          marginBottom: "16px",
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          gap: "16px",
        }}
      >
        <Typography
          variant="body1"
          sx={{ marginRight: "8px", marginTop: "10px" }}
        >
          Showing data for:
        </Typography>
        <TextField
          variant="outlined"
          size="small"
          value={`${formatDateDisplay(selectedStartDate)} - ${formatDateDisplay(selectedEndDate)}`}
          onClick={handleFieldClick}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <InputAdornment position="end">
                <ArrowDropDownIcon />
              </InputAdornment>
            ),
          }}
          sx={{
            minWidth: "255px",
            backgroundColor: "white",
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#E0E0E0",
              },
            },
            "&:hover .MuiOutlinedInput-root fieldset": {
              borderColor: "#C0C0C0",
            },
          }}
        />

        <Dialog open={open} onClose={handleDialogClose}>
          <DialogContent>
            <Grid
              container
              direction="column"
              spacing={2}
              sx={{
                padding: "20px 50px",
              }}
            >
              <Grid item>
                <DateRange
                  ranges={[
                    {
                      startDate: selectedStartDate,
                      endDate: selectedEndDate,
                      key: "selection",
                    },
                  ]}
                  onChange={handleDateChange}
                  moveRangeOnFirstSelection={false}
                  editableDateInputs={true}
                />
              </Grid>
              <Grid container justifyContent="flex-end" spacing={2}>
                <Grid item>
                  <Button variant="text" onClick={handleDialogClose}>
                    Cancel
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleDialogClose}
                  >
                    OK
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </Box>
      {/* Main Content Section */}
      <Box
        sx={{
          padding: "24px",
          backgroundColor: "white",
          borderRadius: "8px",
        }}
      >
        {/* Header */}
        <Typography
          variant="h6"
          sx={{
            marginBottom: "16px",
            color: "#1A73E8",
            fontSize: "15px",
          }}
        >
          On Time Performance
        </Typography>

        <Box
          sx={{
            display: "flex",
            flexDirection: {
              xs: "column",
              md: "row",
            },
            alignItems: {
              xs: "flex-start",
              md: "center",
            },
            gap: "16px",
            marginBottom: "16px",
          }}
        >
          {/* First Card */}
          <Card
            sx={{
              backgroundColor: "white",
              padding: "16px",
              display: "flex",
              flexDirection: "column",
              width: {
                xs: "90%",
                md: "300px",
              },
              margin: {
                xs: "0 auto",
                md: 0,
              },
            }}
          >
            <CardContent
              sx={{
                padding: 0,
                "&:last-child": {
                  paddingTop: "10px",
                  paddingBottom: "10px",
                },
              }}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  color: "#757575",
                  fontWeight: 500,
                }}
              >
                Carrier On Time Performance %
              </Typography>
              {isLoading ? (
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <CircularProgress />
                </Box>
              ) : (
                <Typography
                  sx={{
                    fontSize: "28px",
                    fontWeight: 500,
                    color: "#000",
                  }}
                >
                  {`${onTimePerformanceData?.onTimePerformance ?? 0}%`}
                </Typography>
              )}
            </CardContent>
          </Card>
          <Box
            sx={{
              marginRight: "20px",
              display: "flex",
              flexDirection: {
                xs: "column",
                md: "row",
              },
              justifyContent: "center",
              alignItems: "center",
              gap: "2px", // Small gap
              backgroundColor: "#E0E8FF",
              borderRadius: "8px",
              width: "100%",
            }}
          >
            {/* Late by Carrier */}
            <Card
              sx={{
                flex: 1,
                backgroundColor: "transparent",
                boxShadow: "none",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <CardContent
                sx={{
                  padding: 0,
                  "&:last-child": {
                    paddingTop: "10px",
                    paddingBottom: "10px",
                  },
                }}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    color: "#757575",
                    fontWeight: 500,
                    paddingLeft: "10px",
                  }}
                >
                  Late by Carrier %
                </Typography>
                {isLoading ? (
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <Typography
                    sx={{
                      fontSize: "28px",
                      fontWeight: 500,
                      color: "#000",
                    }}
                  >
                    {`${lateCulpabilitiesPerformanceData?.lateByCarrier ?? 0}%`}
                  </Typography>
                )}
              </CardContent>
            </Card>

            {/* Late by Consignee */}
            <Card
              sx={{
                flex: 1,
                backgroundColor: "transparent",
                boxShadow: "none",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <CardContent
                sx={{
                  padding: 0,
                  "&:last-child": {
                    paddingTop: "10px",
                    paddingBottom: "10px",
                  },
                }}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    color: "#757575",
                    fontWeight: 500,
                    paddingLeft: "10px",
                  }}
                >
                  Late by Consignee %
                </Typography>
                {isLoading ? (
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <Typography
                    sx={{
                      fontSize: "28px",
                      fontWeight: 500,
                      color: "#000",
                    }}
                  >
                    {`${lateCulpabilitiesPerformanceData?.lateByConsignee ?? 0}%`}
                  </Typography>
                )}
              </CardContent>
            </Card>

            {/* Late by Retailer */}
            <Card
              sx={{
                flex: 1,
                backgroundColor: "transparent",
                boxShadow: "none",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <CardContent
                sx={{
                  padding: 0,
                  "&:last-child": {
                    paddingTop: "10px",
                    paddingBottom: "10px",
                  },
                }}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    color: "#757575",
                    fontWeight: 500,
                    paddingLeft: "10px",
                  }}
                >
                  Late by Retailer %
                </Typography>
                {isLoading ? (
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <Typography
                    sx={{
                      fontSize: "28px",
                      fontWeight: 500,
                      color: "#000",
                    }}
                  >
                    {`${lateCulpabilitiesPerformanceData?.lateByRetailer ?? 0}%`}
                  </Typography>
                )}
              </CardContent>
            </Card>
          </Box>
        </Box>

        {/* Total Delivered Section */}
        <Box
          sx={{
            display: "flex",
            flexDirection: {
              xs: "column",
              md: "row",
            },
            alignItems: {
              xs: "flex-start",
              md: "center",
            },
            gap: "16px",
            marginBottom: "16px",
          }}
        >
          {/* First Card */}
          <Card
            sx={{
              backgroundColor: "white",
              padding: "16px",
              display: "flex",
              flexDirection: "column",
              width: {
                xs: "90%",
                md: "300px",
              },
              margin: {
                xs: "0 auto",
                md: 0,
              },
            }}
          >
            <CardContent
              sx={{
                padding: 0,
                "&:last-child": {
                  paddingTop: "10px",
                  paddingBottom: "10px",
                },
              }}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  color: "#757575",
                  fontWeight: 500,
                }}
              >
                Total Delivered %
              </Typography>
              {isLoading ? (
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <CircularProgress />
                </Box>
              ) : (
                <Typography
                  sx={{
                    fontSize: "28px",
                    fontWeight: 500,
                    color: "#000",
                  }}
                >
                  {`${totalDeliveredData?.totalDelivered || 0}%`}
                </Typography>
              )}
            </CardContent>
          </Card>

          <Box
            sx={{
              marginRight: "20px",
              display: "flex",
              flexDirection: {
                xs: "column",
                md: "row",
              },
              justifyContent: "center",
              alignItems: "center",
              gap: "2px",
              backgroundColor: "#E0E8FF",
              borderRadius: "8px",
              width: "100%",
            }}
          >
            {/* Total On-Time */}
            <Card
              sx={{
                flex: 1,
                backgroundColor: "transparent",
                boxShadow: "none",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <CardContent
                sx={{
                  padding: 0,
                  "&:last-child": {
                    paddingTop: "10px",
                    paddingBottom: "10px",
                  },
                }}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    color: "#757575",
                    fontWeight: 500,
                    paddingLeft: "10px",
                  }}
                >
                  Total On-Time %
                </Typography>
                {isLoading ? (
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <Typography
                    sx={{
                      fontSize: "28px",
                      fontWeight: 500,
                      color: "#000",
                    }}
                  >
                    {`${deliveryPerformanceData?.totalOnTime ?? 0}%`}
                  </Typography>
                )}
              </CardContent>
            </Card>

            {/* Total Early */}
            <Card
              sx={{
                flex: 1,
                backgroundColor: "transparent",
                boxShadow: "none",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <CardContent
                sx={{
                  padding: 0,
                  "&:last-child": {
                    paddingTop: "10px",
                    paddingBottom: "10px",
                  },
                }}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    color: "#757575",
                    fontWeight: 500,
                    paddingLeft: "10px",
                  }}
                >
                  Total Early %
                </Typography>
                {isLoading ? (
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <Typography
                    sx={{
                      fontSize: "28px",
                      fontWeight: 500,
                      color: "#000",
                    }}
                  >
                    {`${deliveryPerformanceData?.totalEarly ?? 0}%`}
                  </Typography>
                )}
              </CardContent>
            </Card>

            {/* Total Late */}
            <Card
              sx={{
                flex: 1,
                backgroundColor: "transparent",
                boxShadow: "none",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <CardContent
                sx={{
                  padding: 0,
                  "&:last-child": {
                    paddingTop: "10px",
                    paddingBottom: "10px",
                  },
                }}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    color: "#757575",
                    fontWeight: 500,
                    paddingLeft: "10px",
                  }}
                >
                  Total Late %
                </Typography>
                {isLoading ? (
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <Typography
                    sx={{
                      fontSize: "28px",
                      fontWeight: 500,
                      color: "#000",
                    }}
                  >
                    {`${deliveryPerformanceData?.totalLate ?? 0}%`}
                  </Typography>
                )}
              </CardContent>
            </Card>
          </Box>
        </Box>

        {/* Header */}
        <Typography
          variant="h6"
          sx={{
            marginBottom: "16px",
            color: "#1A73E8",
            fontSize: "15px",
          }}
        >
          Failed Deliveries
        </Typography>

        <Box
          sx={{
            display: "flex",
            flexDirection: {
              xs: "column",
              md: "row",
            },
            alignItems: {
              xs: "flex-start",
              md: "center",
            },
            gap: "16px",
            marginBottom: "16px",
          }}
        >
          {/* First Card */}
          <Card
            sx={{
              backgroundColor: "white",
              padding: "16px",
              display: "flex",
              flexDirection: "column",
              width: {
                xs: "90%",
                md: "300px",
              },
              margin: {
                xs: "0 auto",
                md: 0,
              },
            }}
          >
            <CardContent
              sx={{
                padding: 0,
                "&:last-child": {
                  paddingTop: "10px",
                  paddingBottom: "10px",
                },
              }}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  color: "#757575",
                  fontWeight: 500,
                }}
              >
                Total Failed %
              </Typography>
              {isLoading ? (
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <CircularProgress />
                </Box>
              ) : (
                <Typography
                  sx={{
                    fontSize: "28px",
                    fontWeight: 500,
                    color: "#000",
                  }}
                >
                  {`${totalFailedDeliveriesData?.failed ?? 0}%`}
                </Typography>
              )}
            </CardContent>
          </Card>

          {/* Grouped Cards */}
          <Box
            sx={{
              marginRight: "20px",
              display: "flex",
              flexDirection: {
                xs: "column",
                md: "row",
              },
              justifyContent: "center",
              alignItems: "center",
              gap: "2px",
              backgroundColor: "#E0E8FF",
              borderRadius: "8px",
              width: "100%",
            }}
          >
            <Card
              sx={{
                flex: 1,
                backgroundColor: "transparent",
                boxShadow: "none",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <CardContent
                sx={{
                  padding: 0,
                  "&:last-child": {
                    paddingTop: "10px",
                    paddingBottom: "10px",
                  },
                }}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    color: "#757575",
                    fontWeight: 500,
                    paddingLeft: "10px",
                  }}
                >
                  Total Manco %
                </Typography>
                {isLoading ? (
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <Typography
                    sx={{
                      fontSize: "28px",
                      fontWeight: 500,
                      color: "#000",
                    }}
                  >
                    {`${failedDeliveriesData?.totalManco ?? 0}%`}
                  </Typography>
                )}
              </CardContent>
            </Card>
            <Card
              sx={{
                flex: 1,
                backgroundColor: "transparent",
                boxShadow: "none",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <CardContent
                sx={{
                  padding: 0,
                  "&:last-child": {
                    paddingTop: "10px",
                    paddingBottom: "10px",
                  },
                }}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    color: "#757575",
                    fontWeight: 500,
                    paddingLeft: "10px",
                  }}
                >
                  Total Returned %
                </Typography>
                {isLoading ? (
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <Typography
                    sx={{
                      fontSize: "28px",
                      fontWeight: 500,
                      color: "#000",
                    }}
                  >
                    {`${failedDeliveriesData?.totalReturned ?? 0}%`}
                  </Typography>
                )}
              </CardContent>
            </Card>
            <Card
              sx={{
                flex: 1,
                backgroundColor: "transparent",
                boxShadow: "none",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <CardContent
                sx={{
                  padding: 0,
                  "&:last-child": {
                    paddingTop: "10px",
                    paddingBottom: "10px",
                  },
                }}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    color: "#757575",
                    fontWeight: 500,
                    paddingLeft: "10px",
                  }}
                >
                  Total Damaged/Lost %
                </Typography>
                {isLoading ? (
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <Typography
                    sx={{
                      fontSize: "28px",
                      fontWeight: 500,
                      color: "#000",
                    }}
                  >
                    {`${failedDeliveriesData?.totalDamagedLost ?? 0}%`}
                  </Typography>
                )}
              </CardContent>
            </Card>
          </Box>
        </Box>

        {/* Header */}
        <Typography
          variant="h6"
          sx={{
            marginBottom: "16px",
            color: "#1A73E8",
            fontSize: "15px",
          }}
        >
          Hit Rate
        </Typography>

        <Box
          sx={{
            display: "flex",
            flexDirection: {
              xs: "column",
              md: "row",
            },
            alignItems: {
              xs: "flex-start",
              md: "center",
            },
            gap: "16px",
            marginBottom: "16px",
          }}
        >
          {/* First Card */}
          <Card
            sx={{
              backgroundColor: "white",
              padding: "16px",
              display: "flex",
              flexDirection: "column",
              width: {
                xs: "90%",
                md: "300px",
              },
              margin: {
                xs: "0 auto",
                md: 0,
              },
            }}
          >
            <CardContent
              sx={{
                padding: 0,
                "&:last-child": {
                  paddingTop: "10px",
                  paddingBottom: "10px",
                },
              }}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  color: "#757575",
                  fontWeight: 500,
                }}
              >
                Successful 1st Attempt %
              </Typography>
              {isLoading ? (
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <CircularProgress />
                </Box>
              ) : (
                <Typography
                  sx={{
                    fontSize: "28px",
                    fontWeight: 500,
                    color: "#000",
                  }}
                >
                  {`${hitRateData?.succesfulFirstAttempt ?? 0}%`}
                </Typography>
              )}
            </CardContent>
          </Card>

          {/* Grouped Cards */}
          <Box
            sx={{
              marginRight: "20px",
              display: "flex",
              flexDirection: {
                xs: "column",
                md: "row",
              },
              justifyContent: "center",
              alignItems: "center",
              gap: "2px",
              backgroundColor: "#E0E8FF",
              borderRadius: "8px",
              width: "100%",
            }}
          >
            <Card
              sx={{
                flex: 1,
                backgroundColor: "transparent",
                boxShadow: "none",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <CardContent
                sx={{
                  padding: 0,
                  "&:last-child": {
                    paddingTop: "10px",
                    paddingBottom: "10px",
                  },
                }}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    color: "#757575",
                    fontWeight: 500,
                    paddingLeft: "10px",
                  }}
                >
                  Not Delivered 1st Attempt %
                </Typography>
                {isLoading ? (
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <Typography
                    sx={{
                      fontSize: "28px",
                      fontWeight: 500,
                      color: "#000",
                    }}
                  >
                    {`${hitRateData?.notDeliveredFirstAttempt ?? 0}%`}
                  </Typography>
                )}
              </CardContent>
            </Card>
            <Card
              sx={{
                flex: 1,
                backgroundColor: "transparent",
                boxShadow: "none",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <CardContent
                sx={{
                  padding: 0,
                  "&:last-child": {
                    paddingTop: "10px",
                    paddingBottom: "10px",
                  },
                }}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    color: "#757575",
                    fontWeight: 500,
                    paddingLeft: "10px",
                  }}
                ></Typography>
                <Typography
                  sx={{
                    fontSize: "28px",
                    fontWeight: 500,
                    color: "#000",
                  }}
                ></Typography>
              </CardContent>
            </Card>
            <Card
              sx={{
                flex: 1,
                backgroundColor: "transparent",
                boxShadow: "none",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <CardContent
                sx={{
                  padding: 0,
                  "&:last-child": {
                    paddingTop: "10px",
                    paddingBottom: "10px",
                  },
                }}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    color: "#757575",
                    fontWeight: 500,
                    paddingLeft: "10px",
                  }}
                ></Typography>
                <Typography
                  sx={{
                    fontSize: "28px",
                    fontWeight: 500,
                    color: "#000",
                  }}
                ></Typography>
              </CardContent>
            </Card>
          </Box>
        </Box>

        {/* Header */}
        <Typography
          variant="h6"
          sx={{
            marginBottom: "16px",
            color: "#1A73E8",
            fontSize: "15px",
          }}
        >
          Sustainability
        </Typography>
        {/* Vehicle Type */}

        <Box
          sx={{
            display: "flex",
            flexDirection: {
              xs: "column",
              md: "row",
            },
            alignItems: {
              xs: "flex-start",
              md: "center",
            },
            gap: "16px",
            marginBottom: "16px",
          }}
        >
          {/* First Card */}
          <Card
            sx={{
              backgroundColor: "white",
              padding: "16px",
              display: "flex",
              flexDirection: "column",
              width: {
                xs: "90%",
                md: "300px",
              },
              margin: {
                xs: "0 auto",
                md: 0,
              },
            }}
          >
            <CardContent
              sx={{
                padding: 0,
                "&:last-child": {
                  paddingTop: "10px",
                  paddingBottom: "10px",
                },
              }}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  color: "#757575",
                  fontWeight: 500,
                }}
              >
                CargoBike %
              </Typography>
              {isLoading ? (
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <CircularProgress />
                </Box>
              ) : (
                <Typography
                  sx={{
                    fontSize: "28px",
                    fontWeight: 500,
                    color: "#000",
                  }}
                >
                  {`${vehicleTypeData?.cargoBike ?? 0}%`}
                </Typography>
              )}
            </CardContent>
          </Card>

          {/* Grouped Cards */}
          <Box
            sx={{
              marginRight: "20px",
              display: "flex",
              flexDirection: {
                xs: "column",
                md: "row",
              },
              justifyContent: "center",
              alignItems: "center",
              gap: "2px",
              backgroundColor: "#E0E8FF",
              borderRadius: "8px",
              width: "100%",
            }}
          >
            <Card
              sx={{
                flex: 1,
                backgroundColor: "transparent",
                boxShadow: "none",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <CardContent
                sx={{
                  padding: 0,
                  "&:last-child": {
                    paddingTop: "10px",
                    paddingBottom: "10px",
                  },
                }}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    color: "#757575",
                    fontWeight: 500,
                    paddingLeft: "10px",
                  }}
                >
                  Cargobike + EV %
                </Typography>
                {isLoading ? (
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <Typography
                    sx={{
                      fontSize: "28px",
                      fontWeight: 500,
                      color: "#000",
                    }}
                  >
                    {`${vehicleTypeData?.bikePlusEv ?? 0}%`}
                  </Typography>
                )}
              </CardContent>
            </Card>

            <Card
              sx={{
                flex: 1,
                backgroundColor: "transparent",
                boxShadow: "none",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <CardContent
                sx={{
                  padding: 0,
                  "&:last-child": {
                    paddingTop: "10px",
                    paddingBottom: "10px",
                  },
                }}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    color: "#757575",
                    fontWeight: 500,
                    paddingLeft: "10px",
                  }}
                >
                  Hybrid %
                </Typography>
                {isLoading ? (
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <Typography
                    sx={{
                      fontSize: "28px",
                      fontWeight: 500,
                      color: "#000",
                    }}
                  >
                    {`${vehicleTypeData?.hybrid ?? 0}%`}
                  </Typography>
                )}
              </CardContent>
            </Card>

            <Card
              sx={{
                flex: 1,
                backgroundColor: "transparent",
                boxShadow: "none",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <CardContent
                sx={{
                  padding: 0,
                  "&:last-child": {
                    paddingTop: "10px",
                    paddingBottom: "10px",
                  },
                }}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    color: "#757575",
                    fontWeight: 500,
                    paddingLeft: "10px",
                  }}
                >
                  Unknown %
                </Typography>
                {isLoading ? (
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <Typography
                    sx={{
                      fontSize: "28px",
                      fontWeight: 500,
                      color: "#000",
                    }}
                  >
                    {`${vehicleTypeData?.unknown ?? 0}%`}
                  </Typography>
                )}
              </CardContent>
            </Card>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default RetailerBreakdown;
