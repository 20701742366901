import React, { useState } from "react";
import { getRequestUI } from "common-utils/utils/api";
import { DateTime } from "luxon";
import CustomizedPieChart from "../components/CustomPieChart";
import { useQuery } from "react-query";
import { DateRange } from "react-date-range";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  Box,
  CardContent,
  Card,
  Typography,
  Grid,
  Button,
  Dialog,
  DialogContent,
  InputAdornment,
  TextField,
} from "@mui/material";
const formatDate = (date) => DateTime.fromJSDate(date).toFormat("yyyy-MM-dd");

const formatDateDisplay = (date) =>
  DateTime.fromJSDate(date).toFormat("dd LLL yyyy");

const RetailerOverview = () => {
  const today = new Date();
  const [dateRange, setDateRange] = useState({
    startDate: new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() - 30,
    ),
    endDate: today,
  });
  const [selectedDateRange, setSelectedDateRange] = useState(dateRange);

  const [open, setOpen] = useState(false);

  const handleFieldClick = () => setOpen(true);

  const handleDateChange = (ranges) => {
    const { startDate, endDate } = ranges.selection;
    setSelectedDateRange({ startDate, endDate });
  };

  const handleDialogClose = () => setOpen(false);

  const handleDialogConfirm = () => {
    setDateRange(selectedDateRange);
    setOpen(false);
  };

  const getFilterData = () => ({
    startDate: formatDate(dateRange.startDate),
    endDate: formatDate(dateRange.endDate),
  });

  const fetchAnalyticsData = async (endpoint) => {
    return getRequestUI(endpoint, getFilterData());
  };

  const queryOptions = {
    enabled: !!dateRange.startDate && !!dateRange.endDate,
    refetchOnWindowFocus: false,
  };

  const { data: totalData } = useQuery(
    ["total", dateRange.startDate, dateRange.endDate],
    () => fetchAnalyticsData("/retailer/analytics/total"),
    queryOptions,
  );

  const { data: perCarrierData } = useQuery(
    ["per-carrier", dateRange.startDate, dateRange.endDate],
    () => fetchAnalyticsData("/retailer/analytics/per-carrier"),
    queryOptions,
  );

  const { data: topCitiesData } = useQuery(
    ["top-cities", dateRange.startDate, dateRange.endDate],
    () => fetchAnalyticsData("/retailer/analytics/top10-cities"),
    queryOptions,
  );

  const { data: firstAttemptResults } = useQuery(
    ["first-attempt", dateRange.startDate, dateRange.endDate],
    () => fetchAnalyticsData("/retailer/analytics/first-attempt-results"),
    queryOptions,
  );

  const { data: timeframeStatus } = useQuery(
    ["timeframe", dateRange.startDate, dateRange.endDate],
    () => fetchAnalyticsData("/retailer/analytics/timeframe-status"),
    queryOptions,
  );

  const { data: timelineData } = useQuery(
    ["timeline", dateRange.startDate, dateRange.endDate],
    () => fetchAnalyticsData("/retailer/analytics/timeline"),
    queryOptions,
  );

  return (
    <Box sx={{ width: "100%", padding: "0px", margin: "0px" }}>
      <Box display="flex" justifyContent="flex-end" mb={2}>
        <Typography
          variant="body1"
          style={{ marginRight: "8px", marginTop: "10px" }}
        >
          Showing data for :
        </Typography>

        {/* Date Range Input Field */}
        <TextField
          variant="outlined"
          size="small"
          value={`${formatDateDisplay(dateRange.startDate)} - ${formatDateDisplay(
            dateRange.endDate,
          )}`}
          onClick={handleFieldClick}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <InputAdornment position="end">
                <ArrowDropDownIcon />
              </InputAdornment>
            ),
          }}
          sx={{
            minWidth: "255px",
            backgroundColor: "white",
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#E0E0E0", // Light gray border
              },
            },
            "&:hover .MuiOutlinedInput-root fieldset": {
              borderColor: "#C0C0C0",
            },
          }}
        />

        {/* Date Picker Dialog */}
        <Dialog open={open} onClose={handleDialogClose}>
          <DialogContent>
            <Grid
              container
              direction="column"
              spacing={2}
              sx={{
                padding: "20px 50px",
              }}
            >
              <Grid item>
                <DateRange
                  ranges={[
                    {
                      startDate: selectedDateRange.startDate,
                      endDate: selectedDateRange.endDate,
                      key: "selection",
                    },
                  ]}
                  onChange={handleDateChange}
                  moveRangeOnFirstSelection={false}
                  editableDateInputs={true}
                />
              </Grid>
              <Grid container justifyContent="flex-end" spacing={2}>
                <Grid item>
                  <Button variant="text" onClick={handleDialogClose}>
                    Cancel
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleDialogConfirm}
                  >
                    OK
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </Box>
      <Box
        sx={{
          transformOrigin: "top", // Align scaling to the top
        }}
      >
        <Grid
          container
          spacing={2}
          style={{
            margin: 0,
            width: "100%",
            backgroundColor: "rgb(239, 243, 246)",
          }}
        >
          {/* First Card */}
          <Grid item xs={12} md={4}>
            <Card style={{ backgroundColor: "white", textAlign: "left" }}>
              <CardContent>
                <Typography
                  variant="h6"
                  style={{
                    marginBottom: "8px",
                  }}
                >
                  Total
                </Typography>
                <Typography
                  variant="subtitle2"
                  style={{ color: "grey", marginBottom: "16px" }}
                >
                  Sales Summary
                </Typography>

                {/* Purple Card */}
                <Box
                  sx={{
                    backgroundColor: "#E0E8FF",
                    borderRadius: "12px",
                    padding: "16px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Box
                    sx={{
                      backgroundColor: "#4B7BFF",
                      borderRadius: "50%",
                      padding: "8px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "8px",
                    }}
                  >
                    {/* Chart Icon */}
                    <img
                      src="https://img.icons8.com/ios-filled/50/ffffff/combo-chart.png"
                      alt="chart-icon"
                      style={{ width: "24px", height: "24px" }}
                    />
                  </Box>
                  <Typography
                    variant="h4"
                    style={{
                      fontWeight: "bold",
                      color: "#000000",
                    }}
                  >
                    {totalData?.total || "0"}
                  </Typography>
                  <Typography
                    variant="body2"
                    style={{
                      color: "#5F6C7B",
                    }}
                  >
                    Shipments
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>

          {/* Second Card */}
          <Grid item xs={12} md={4}>
            <Card>
              <CardContent sx={{ minHeight: "260px" }}>
                {" "}
                {/* Set a consistent height */}
                <Typography variant="h6" sx={{ mb: 2 }}>
                  Volume per carrier
                </Typography>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  sx={{ color: "text.secondary", mb: 1 }}
                >
                  <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                    Carrier
                  </Typography>
                  <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                    Number
                  </Typography>
                </Box>
                {perCarrierData?.perCarrier.values
                  ?.slice(0, 5)
                  .map((carrier, index) => (
                    <React.Fragment key={index}>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        py={0.5}
                      >
                        <Typography variant="body2">{carrier.label}</Typography>
                        <Typography variant="body2">{carrier.value}</Typography>
                      </Box>
                      {index < 4 && (
                        <Box sx={{ borderBottom: "1px solid #E0E0E0" }} />
                      )}
                    </React.Fragment>
                  ))}
              </CardContent>
            </Card>
          </Grid>

          {/* Third Card */}
          <Grid item xs={12} md={4}>
            <Card>
              <CardContent sx={{ minHeight: "260px" }}>
                {" "}
                {/* Set a consistent height */}
                <Typography variant="h6" sx={{ mb: 2 }}>
                  Volume Density
                </Typography>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  sx={{ color: "text.secondary", mb: 1 }}
                >
                  <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                    Location
                  </Typography>
                  <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                    Number
                  </Typography>
                </Box>
                {topCitiesData?.top10Cities.values
                  ?.slice(0, 5)
                  .map((city, index) => (
                    <React.Fragment key={index}>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        py={0.5}
                      >
                        <Typography variant="body2">{city.label}</Typography>
                        <Typography variant="body2">{city.value}</Typography>
                      </Box>
                      {index < 4 && (
                        <Box sx={{ borderBottom: "1px solid #E0E0E0" }} />
                      )}
                    </React.Fragment>
                  ))}
              </CardContent>
            </Card>
          </Grid>

          {/* Pie Charts */}
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={4}>
                    <CustomizedPieChart
                      data={
                        firstAttemptResults?.firstAttemptResults.percents || []
                      }
                      title="Hit Rate (1st attempt)"
                      subtitle="Number of successful deliveries at the first attempt"
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <CustomizedPieChart
                      data={timeframeStatus?.timeframeStatus.percents || []}
                      title="On-Time"
                      subtitle="On-time performance & culpability late shipments"
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <CustomizedPieChart
                      data={timelineData?.timeline.percents || []}
                      title="On-Time Performance"
                      subtitle="On-time performance (time frame only)"
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default RetailerOverview;
