import React from "react";
import { ResponsivePie } from "@nivo/pie";
import Typography from "@mui/material/Typography";

const CustomizedPieChart = ({ data, title, subtitle }) => {
  const chartData = data.map((item) => ({
    id: item.label,
    value: item.value,
    label: item.label, // Retain 'label' for clarity
  }));

  return (
    <div style={{ width: "100%", height: "330px", textAlign: "center" }}>
      {/* Title and Subtitle */}
      <Typography variant="h6" style={{ marginBottom: "4px" }}>
        {title}
      </Typography>
      {subtitle && (
        <Typography
          variant="body2"
          color="textSecondary"
          style={{ marginBottom: "8px" }}
        >
          {subtitle}
        </Typography>
      )}

      {/* Nivo Donut Chart */}
      <ResponsivePie
        data={chartData}
        innerRadius={0.6}
        padAngle={1}
        cornerRadius={3}
        colors={{ scheme: "category10" }}
        arcLabel={(item) => `${item.value}%`}
        margin={{ top: 20, right: 40, bottom: 90, left: 40 }}
        arcLinkLabelsSkipAngle={10}
        arcLinkLabelsTextColor="#333"
        arcLinkLabelsThickness={2}
        arcLinkLabelsColor={{ from: "color" }}
        enableArcLinkLabels={false}
        arcLabelsSkipAngle={10}
        arcLabelsTextColor="#000"
        theme={{
          labels: { text: { fontSize: 12, fontWeight: "bold" } },
        }}
      />
    </div>
  );
};

export default CustomizedPieChart;
