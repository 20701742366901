import React, { useState, useEffect } from "react";
import { getRequestUI } from "common-utils/utils/api";
import { useQuery } from "react-query";
import { DateTime } from "luxon";

import {
  Box,
  Card,
  CardContent,
  Typography,
  ToggleButton,
  ToggleButtonGroup,
  Stack,
  Chip,
} from "@mui/material";
import { BarChart } from "@mui/x-charts/BarChart";
import { useTheme } from "@mui/material/styles";

const RetailerBenchmarks = () => {
  const [selectedOption, setSelectedOption] = useState("performance");

  const theme = useTheme();
  const colorPalette = [
    (theme.vars || theme).palette.primary.dark,
    (theme.vars || theme).palette.primary.main,
    (theme.vars || theme).palette.primary.light,
  ];

  const formatMonth = (dateString) => {
    return DateTime.fromFormat(dateString, "yyyy-MM").toFormat("yyyy MMM");
  };
  const benchmarksTotal = useQuery(["benchmarks-total"], () =>
    getRequestUI("/retailer/benchmarks/total"),
  );
  const benchmarksDelivery = useQuery(["benchmarks-delivery"], () =>
    getRequestUI("/retailer/benchmarks/delivery"),
  );

  useEffect(() => {
    benchmarksTotal.refetch();
    benchmarksDelivery.refetch();
  }, []);

  const handleOptionChange = (_, newOption) => {
    if (newOption) setSelectedOption(newOption);
  };

  const dataset =
    selectedOption === "performance"
      ? benchmarksTotal.data?.percents || []
      : benchmarksDelivery.data?.percents || [];

  const keys = selectedOption === "performance" ? ["On Time"] : ["Incompleted"];
  const getPercentSeries = (data, keys) => {
    console.log("Dataset:", data);

    if (!data || data.length === 0) return [];

    const [item] = data;
    return Object.keys(item)
      .filter((key) => keys.includes(key))
      .map((key, index) => ({
        dataKey: key,
        label: key,
        color: colorPalette[index % colorPalette.length],
        stack: "total",
      }));
  };

  const formattedDataset = dataset.map((item) => ({
    ...item,
    month: formatMonth(item.month),
  }));
  return (
    <Box sx={{ width: "100%", padding: "0px", margin: "0px" }}>
      <Card variant="outlined" sx={{ width: "100%" }}>
        <CardContent>
          <Typography variant="h6" sx={{ mb: 1 }}>
            Comparison
          </Typography>{" "}
          <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
            <ToggleButtonGroup
              value={selectedOption}
              exclusive
              onChange={handleOptionChange}
              aria-label="Benchmark Options"
              sx={{
                "& .MuiToggleButtonGroup-grouped": {
                  margin: 0.5, // Spacing between buttons
                  borderRadius: "8px", // Rounded edges
                  fontSize: "0.75rem", // Smaller text
                  padding: "4px 8px", // Adjust button size
                  border: "1px solid", // Keeps border visibility
                  borderColor: theme.palette.divider,
                },
              }}
            >
              <ToggleButton
                value="performance"
                sx={{
                  backgroundColor:
                    selectedOption === "performance"
                      ? theme.palette.primary.main // Active: Blue
                      : "white", // Inactive: White
                  color:
                    selectedOption === "performance"
                      ? "white" // Active: White text
                      : theme.palette.text.primary, // Inactive: Black text
                  "&:hover": {
                    backgroundColor: theme.palette.primary.dark,
                    color: "white",
                  },
                  "&.Mui-selected": {
                    backgroundColor: theme.palette.primary.main, // Selected: Blue
                    color: "white", // Selected text: White
                    "&:hover": {
                      backgroundColor: theme.palette.primary.dark, // Darker blue on hover
                    },
                  },
                }}
              >
                Performance
              </ToggleButton>
              <ToggleButton
                value="incomplete"
                sx={{
                  backgroundColor:
                    selectedOption === "incomplete"
                      ? theme.palette.primary.main // Active: Blue
                      : "white", // Inactive: White
                  color:
                    selectedOption === "incomplete"
                      ? "white" // Active: White text
                      : theme.palette.text.primary, // Inactive: Black text
                  "&:hover": {
                    backgroundColor: theme.palette.primary.dark,
                    color: "white",
                  },
                  "&.Mui-selected": {
                    backgroundColor: theme.palette.primary.main, // Selected: Blue
                    color: "white", // Selected text: White
                    "&:hover": {
                      backgroundColor: theme.palette.primary.dark, // Darker blue on hover
                    },
                  },
                }}
              >
                Incomplete
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
          {/* Bar Chart */}
          {dataset.length > 0 ? (
            <BarChart
              dataset={formattedDataset} // Pass the correct dataset
              series={getPercentSeries(dataset, keys)} // Generate series dynamically
              xAxis={[
                { scaleType: "band", dataKey: "month", categoryGapRatio: 0.8 },
              ]} // Use 'month' for x-axis
              height={320}
              grid={{ horizontal: true }}
              margin={{ left: 50, right: 20, top: 20, bottom: 20 }}
              borderRadius={8}
              slotProps={{
                legend: { hidden: true },
              }}
            />
          ) : (
            <Typography align="center" variant="subtitle1">
              No data available
            </Typography>
          )}
        </CardContent>
      </Card>
    </Box>
  );
};

export default RetailerBenchmarks;
