import React, { useState, useCallback } from "react";
import _ from "lodash";
import "date-fns";
import Alert from "@mui/material/Alert";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Snackbar from "@mui/material/Snackbar";
import Button from "@mui/material/Button";
import VisibilityIcon from "@mui/icons-material/Visibility";
import LinearProgress from "@mui/material/LinearProgress";
import CircularProgress from "@mui/material/CircularProgress";
import FileDownload from "js-file-download";
import ReportIcon from "@mui/icons-material/Report";
import { useOutletContext } from "react-router-dom";

import {
  formatLocationInfo,
  formatAddress,
  formatStatusName,
} from "common-utils/utils/formatter";
import {
  getRequestUI,
  deleteRequestUI,
  postRequestUI,
  getPDFRequestUI,
} from "common-utils/utils/api";

import Filters from "../components/Filters";

import {
  IconTrash,
  RetailerOrdersTable,
  ShippingLabelButton,
  OrderInfo,
  DateFormatter,
  AlertDialog,
  OrderClaim,
} from "@shared/components/lib/index";
import Tooltip from "@mui/material/Tooltip";
import { DateTime } from "luxon";
import { isEmpty } from "lodash";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { TIME_SHOW_ERROR } from "common-utils/src/constants";
import useTheme from "@mui/material/styles/useTheme";
import { captureException } from "@sentry/react";

const useStyles = (theme) => ({
  deliveredStatus: {
    background: "#d7ecfa",
  },
  status: {
    padding: "5 10",
    borderRadius: 20,
    background: "#ffd9d4",
    textAlign: "center",
  },
  circleProgress: {
    marginLeft: 12,
  },
  statusCell: {
    minWidth: "120px",
  },
  infoButton: {
    "& svg": {
      color: theme.palette.common.black,
    },
  },
  filterBlock: {
    padding: 0,
  },
});

function RetailerOrders() {
  const theme = useTheme();
  const styles = useStyles(theme);
  const { auth, userClaims } = useOutletContext() || {};
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showProgressDialogs, setShowProgress] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);
  const [selectedStartPickUpDateFilter, setSelectedStartPickUpDateFilter] =
    useState(new Date());
  const [selectedEndPickUpDateFilter, setSelectedEndPickUpDateFilter] =
    useState(new Date());
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [currentOrder, setCurrentOrder] = useState({});
  const [selectedLocationsFilter, setSelectedLocationsFilter] = useState([]);
  const [openInfoDialog, setOpenInfoDialog] = useState(false);
  const [openClaimsDialog, setOpenClaimsDialog] = useState(false);
  const [isDeleting, setDeleting] = useState(false);
  const [selectedStatusFilter, setSelectedStatusFilter] = useState([]);
  const [selectedReasonFilter, setSelectedReasonFilter] = useState([]);
  const [selectedTrackingCodeFilter, setSelectedTrackingCodeFilter] = useState(
    [],
  );
  const [orders, setOrders] = useState([]);
  const [totalNumbers, setTotalNumbers] = useState(0);
  const [open, setOpen] = useState(false);
  const [selectedZipCodeFilter, setSelectedZipCodeFilter] = useState("");
  const [showPopupWindowAlert, setShowPopupWindowAlert] = useState(false);
  const [isLoadingPDF, setIsLoadingPDF] = useState(false);
  const [pagination, changePagination] = useState({
    page: 0,
    rowsPerPage: 20,
  });

  const queryClient = useQueryClient();

  const ordersQuery = useQuery(["orders", pagination], getOrders, {
    enabled: !!userClaims?.userType,
    onSuccess: (orders) => {
      queryClient.setQueryData(["orders"], orders);
      setOrders(orders.data);
      setTotalNumbers(orders.totalNumbers);
    },
  });

  const exportQuery = useMutation(["export-orders"], exportOrders, {
    enabled: false,
  });
  const searchTrackingCodeQuery = useQuery(
    ["search-orders-trackingCode"],
    searchOrdersByTrackingCodes,
    {
      enabled: false,
      onSuccess: (orders) => {
        queryClient.setQueryData(["orders"], orders);
        setOrders(orders);
        setTotalNumbers(orders.length);
        changePagination({ ...pagination, page: 0 });
      },
    },
  );
  const prerequisites = useQuery(
    ["initRetailerOrderPrerequisites"],
    getPrerequisites,
    {
      staleTime: Infinity,
    },
  );
  const deleteOrderMutation = useMutation(setDeletedOrderStatus, {
    onSuccess: onSuccessMutation,
  });

  const resetPageNumber = () => {
    changePagination({ ...pagination, page: 0 });
  };

  function getOrders() {
    const filters = getFilterData();
    return getRequestUI("/retailer/history/orders", filters);
  }
  function getPrerequisites() {
    return getRequestUI("/retailer/orders-prerequisites");
  }

  async function setDeletedOrderStatus(id) {
    setDeleting(id);
    await deleteRequestUI("/retailer/status", { id });
    setDeleting();
  }
  async function exportOrders() {
    setExportLoading(true);
    const filters = getFilterData();
    const response = await postRequestUI("export-orders/retailer", filters);
    FileDownload(
      response,
      `orders_${filters.startDate}_${filters.endDate}.csv`,
    );
    setExportLoading(false);
  }

  const openInfoOrder = (order) => {
    setCurrentOrder(order);
    setOpenInfoDialog(true);
  };

  const openClaimsOrder = (order) => {
    setCurrentOrder(order);
    setOpenClaimsDialog(true);
  };

  const handleCloseClaims = () => {
    setOpenClaimsDialog(false);
  };

  const handleCloseInfo = () => {
    setOpenInfoDialog(false);
  };

  const updateTable = () => {
    ordersQuery.refetch();
  };

  const resetPageNumAndUpdateTable = () => {
    resetPageNumber();
    updateTable();
  };

  const handleMultiSelect = useCallback(
    (selectedId, selectedDeliveryDate) => {
      if (selectedOrders.includes(selectedId)) {
        setSelectedOrders(selectedOrders.filter((id) => id !== selectedId));
      } else {
        setSelectedOrders([...selectedOrders, selectedId]);
      }
    },
    [selectedOrders],
  );
  const handleMultipleLabels = useCallback(async () => {
    const trackingCodes = _.uniq(
      _.compact(
        selectedOrders.map((orderId) => {
          const foundedOrder = ordersQuery.data?.data.find(
            (order) => order.id === orderId,
          );
          if (foundedOrder) {
            return foundedOrder.trackingCode;
          }
          return undefined;
        }),
      ),
    );
    if (_.isEmpty(trackingCodes)) {
      setShowError(true);
      setErrorMessage("Please select at least one tracking code");
      return;
    }
    retrieveLabelMutation.mutate({ codes: trackingCodes });
  }, [selectedOrders]);

  async function retrieveLabels(data) {
    setShowPopupWindowAlert(false);
    setIsLoadingPDF(true);
    return getPDFRequestUI("labels", data);
  }

  const retrieveLabelMutation = useMutation(retrieveLabels, {
    onSuccess: (response) => {
      const newWindow = window.open();
      if (!newWindow) {
        setShowPopupWindowAlert(true);
        return;
      }
      const blob = new Blob([response.data], { type: "application/pdf" });
      const objectURL = URL.createObjectURL(blob);
      newWindow.location.href = objectURL;
    },
    onError: (error) => {
      captureException(error.message);
    },
    onSettled: () => {
      setIsLoadingPDF(false);
    },
  });

  function onSuccessMutation() {
    queryClient.invalidateQueries("orders");
  }

  const handleGetOrdersByTrackingCode = () => {
    searchTrackingCodeQuery.refetch();
  };

  function searchOrdersByTrackingCodes() {
    return getRequestUI(
      `/retailer/order/search-tracking-codes?trackingCodes=${selectedTrackingCodeFilter}`,
    );
  }

  const getFilterData = () => ({
    startDate: DateTime.fromJSDate(selectedStartPickUpDateFilter).toFormat(
      "yyyy-MM-dd",
    ),
    endDate: DateTime.fromJSDate(selectedEndPickUpDateFilter).toFormat(
      "yyyy-MM-dd",
    ),
    locations: selectedLocationsFilter.map((location) => location.id),
    statuses: selectedStatusFilter.map((status) => status.id),
    reasons: selectedReasonFilter.map((reason) => reason.id),
    postCode: selectedZipCodeFilter,
    trackingCodes: selectedTrackingCodeFilter,
    page: pagination.page,
    rowsPerPage: pagination.rowsPerPage,
  });

  const onExportClick = () => {
    exportQuery.mutate();
  };

  /**
   * Data to be passed to table
   */
  const adminTableData = {
    headers: [
      "Product",
      "Size",
      "Delivery Status",
      "Created At",
      "Description",
      "Pickup date",
      "Pickup at",
      "Delivery Date",
      "Delivery Address",
      "Tracking code",
      "External Id",
      "Two Factor",
      "Label",
      "Details",
      "Claims",
    ],
    body: [
      (order) => order.product.name,
      (order) => order.size.name,
      (order) => (
        <Tooltip
          title={
            _.get(order, "statusHistory") ? (
              <div>
                {(_.get(order, "statusHistory") || []).map(
                  ({ name, timestamp, reason, remark }) => (
                    <div>
                      <DateFormatter
                        date={timestamp}
                        format="dd/MM/yyyy-HH:mm"
                      />
                      {` ${name} ${reason ? `- ${reason.name}` : ""} ${remark}`}
                      <br />
                    </div>
                  ),
                )}
              </div>
            ) : (
              ""
            )
          }
        >
          <Grid container alignItems="center" sx={styles.statusCell}>
            <Grid
              item
              xs={9}
              sx={
                order.status.name === "Delivered"
                  ? { ...styles.status, ...styles.deliveredStatus }
                  : styles.status
              }
            >
              {formatStatusName(order.status, order.statusHistory)}
            </Grid>

            {_.get(order, "status.id") !== "DELETED" &&
              (deleteOrderMutation.isLoading && isDeleting === order.id ? (
                <CircularProgress size={20} sx={styles.circleProgress} />
              ) : (
                <Grid item xs={3} onClick={() => handleClickOpen(order)}>
                  &nbsp;
                  <IconTrash color={"#7F8FA4"} />
                </Grid>
              ))}
          </Grid>
        </Tooltip>
      ),
      (order) => (
        <DateFormatter date={order.createdAt} format={"dd-MM-yyyy HH:mm"} />
      ),
      (order) => order.description,
      (order) => <DateFormatter date={order.pickupDate} format="dd-MM-yyyy" />,
      (order) =>
        formatLocationInfo(order.pickupAddress.name) +
        formatAddress(
          order.pickupAddress.address.postcode,
          order.pickupAddress.address.houseNumber,
          order.pickupAddress.address.houseNumberAdditions,
          order.pickupAddress.address.street,
        ),
      (order) => (
        <DateFormatter date={order.deliveryDate} format="dd-MM-yyyy" />
      ),
      (order) =>
        formatAddress(
          order.deliveryAddress.postcode,
          order.deliveryAddress.houseNumber,
          order.deliveryAddress.houseAdditions,
          order.deliveryAddress.street,
        ),
      (order) => order.trackingCode,
      (order) => order.externalId,
      (order) => (order.hasTwoFactorCode ? "Yes" : "No"),
      (order) => (
        <ShippingLabelButton trackingCode={order.trackingCode} label="PDF" />
      ),
      (order) => (
        <Button
          sx={styles.infoButton}
          startIcon={<VisibilityIcon />}
          onClick={() => openInfoOrder(order)}
        />
      ),
      (order) =>
        order.hasOrderClaim ? (
          "Claimed"
        ) : (
          <Button
            sx={styles.infoButton}
            startIcon={<ReportIcon />}
            onClick={() => openClaimsOrder(order)}
          />
        ),
    ],
  };

  const handleDelete = (orderId) => {
    deleteOrderMutation.mutate(orderId);
  };
  const handleClickOpen = (order) => {
    setCurrentOrder(order);
    setOpen(true);
  };
  const handleSuccess = () => {
    handleDelete(currentOrder.id);
    setOpen(false);
    setCurrentOrder({});
  };
  const handleClose = () => {
    setOpen(false);
  };

  const statusHistoryFormatter = (statusHistory) => {
    return `${statusHistory.name}
    ${statusHistory.reason ? `- ${statusHistory.reason.name}` : ""} ${
      statusHistory.remark
    }`;
  };

  const showProgress = ordersQuery.isLoading || showProgressDialogs;
  const getErrorMessage = () => {
    if (ordersQuery.isError) {
      return ordersQuery.error instanceof Error
        ? ordersQuery.error.message
        : "Error by exporting orders";
    }
    if (deleteOrderMutation.isError) {
      return deleteOrderMutation.error instanceof Error
        ? deleteOrderMutation.error.message
        : "Error by deleting order";
    }
    if (prerequisites.isError) {
      return prerequisites.error instanceof Error
        ? prerequisites.error.message
        : "Error by getting prerequisites";
    }
    if (exportQuery.isError) {
      return exportQuery.error instanceof Error
        ? exportQuery.error.message
        : "Error by exporting orders";
    }

    return showError && errorMessage ? errorMessage : "";
  };
  return (
    <Box
      sx={{
        backgroundColor: "white",
      }}
    >
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={showPopupWindowAlert}
        autoHideDuration={3000}
        onClose={() => setShowPopupWindowAlert(false)}
      >
        <Alert severity="info">
          To view PDF Labels please unblocks popups for this site
        </Alert>
      </Snackbar>
      <Box component="span" display={showProgress ? "block" : "none"}>
        <LinearProgress />
      </Box>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={!!getErrorMessage()}
        autoHideDuration={TIME_SHOW_ERROR}
      >
        <Alert severity="error">{getErrorMessage()}</Alert>
      </Snackbar>
      <Grid container>
        {prerequisites.data && !prerequisites.error && (
          <Filters
            locations={prerequisites.data.locations}
            statuses={prerequisites.data.statuses}
            reasons={prerequisites.data.reasons}
            selectedLocationsFilter={selectedLocationsFilter}
            setSelectedLocationsFilter={setSelectedLocationsFilter}
            selectedStartPickUpDateFilter={selectedStartPickUpDateFilter}
            setSelectedStartPickUpDateFilter={setSelectedStartPickUpDateFilter}
            selectedEndPickUpDateFilter={selectedEndPickUpDateFilter}
            setSelectedEndPickUpDateFilter={setSelectedEndPickUpDateFilter}
            selectedStatusFilter={selectedStatusFilter}
            setSelectedStatusFilter={setSelectedStatusFilter}
            selectedReasonFilter={selectedReasonFilter}
            setSelectedReasonFilter={setSelectedReasonFilter}
            selectedTrackingCodeFilter={selectedTrackingCodeFilter}
            setSelectedTrackingCodeFilter={setSelectedTrackingCodeFilter}
            getOrderByTrackingCodes={handleGetOrdersByTrackingCode}
            selectedZipCodeFilter={selectedZipCodeFilter}
            setSelectedZipCodeFilter={setSelectedZipCodeFilter}
            onExportClick={onExportClick}
            exportLoading={exportLoading}
            onApplyFilter={resetPageNumAndUpdateTable}
          />
        )}
      </Grid>
      <RetailerOrdersTable
        headers={adminTableData.headers}
        rowData={orders || []}
        cellComponents={adminTableData.body}
        multiSelect
        selectedRows={selectedOrders}
        setSelectedOrders={setSelectedOrders}
        onSelect={handleMultiSelect}
        onGetMultipleLabels={handleMultipleLabels}
        pagination={pagination}
        totalRowsNumber={totalNumbers}
        changePagination={changePagination}
        isLoadingPDF={isLoadingPDF}
      />
      <AlertDialog
        open={open}
        handleClose={handleClose}
        handleSuccess={handleSuccess}
        text={`Are you sure you want to delete this order?`}
      />
      {!isEmpty(currentOrder) && (
        <>
          <OrderInfo
            order={currentOrder}
            showCarrierInfo={false}
            isOpenOrderInfo={openInfoDialog}
            handleCloseInfo={handleCloseInfo}
            setShowError={setShowError}
            setErrorMessage={setErrorMessage}
            showGpsStamp={true}
            setShowProgress={setShowProgress}
            statusHistoryFormatter={statusHistoryFormatter}
          />
          <OrderClaim
            order={currentOrder}
            isOpenOrderInfo={openClaimsDialog}
            handleCloseInfo={handleCloseClaims}
            onSave={updateTable}
          />
        </>
      )}
    </Box>
  );
}
export default RetailerOrders;
