import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import CssBaseline from "@mui/material/CssBaseline";
import { theme } from "@shared/components/lib/index";
import "./css/main.css";
import { createTheme } from "@mui/material/styles";

export default function Main({ children }) {
  const queryClient = new QueryClient();

  const theme = createTheme({
    components: {
      MuiContainer: {
        styleOverrides: {
          root: {
            maxWidth: "1600px" /* Fixed max width */,
            margin: "0 auto", // Center horizontally
            paddingLeft: "16px", // Add small padding
            paddingRight: "16px",
          },
        },
      },
    },
  });
  return (
    <QueryClientProvider client={queryClient}>
      <CssBaseline />
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </QueryClientProvider>
  );
}
